import React, { useState } from 'react';
import * as styles from './payment-methods.module.scss';

type PaymentMethod = {
  slug: string;
  name: string;
  description: string;
  icon: JSX.Element;
}

type PaymentMethodsProps = {
  value?: string;
  onChange?: (value: string) => any;
  paymentMethods: PaymentMethod[]
};

export default function PaymentMethods({ paymentMethods, value, onChange }: PaymentMethodsProps) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(value);

  const handleChange = (slug: string) => {
    setSelectedPaymentMethod(slug);
    if(onChange) {
      onChange(slug);
    }
  }

  return (
    <div>
      {paymentMethods.map((paymentMethod, index) => (
        <div key={index} className={styles.paymentMethod} onClick={() => handleChange(paymentMethod.slug)}>
          <div
            className={`${styles.radioButton} ${selectedPaymentMethod === paymentMethod.slug ? styles.radioButtonSelected : ''}`}></div>
          <p className={styles.name}>{paymentMethod.name}</p>
          <p className={styles.description}>{paymentMethod.description}</p>
        </div>
      ))}
    </div>
  );
}