// extracted by mini-css-extract-plugin
export var content = "checkout-module--content--1IRJW";
export var container = "checkout-module--container--1jTgG";
export var cartInformation = "checkout-module--cart-information--3qsAe";
export var cartInformation__heading = "checkout-module--cart-information__heading--2_UMS";
export var cartInformation__price = "checkout-module--cart-information__price--WSv1k";
export var cartInformation__travelers = "checkout-module--cart-information__travelers--3dYDr";
export var cartInformation__dates = "checkout-module--cart-information__dates--gYaeE";
export var paymentMethod__container = "checkout-module--payment-method__container--1QAbH";
export var total__container = "checkout-module--total__container--1gYil";
export var card__container = "checkout-module--card__container--3xFqW";
export var card__disclaimer = "checkout-module--card__disclaimer--dtNA2";
export var totalContainer = "checkout-module--total-container--2d-V7";
export var buttonContainer = "checkout-module--button-container--1buHq";
export var errorMessage = "checkout-module--error-message--28MEM";
export var modalHeading = "checkout-module--modal-heading--RnGCd";
export var modalBody = "checkout-module--modal-body--5iUKy";
export var modalButton = "checkout-module--modal-button--13az7";