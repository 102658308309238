import { LargeButton } from '@/src/components/button/button';
import Footer from '@/src/components/footer/footer';
import Header from '@/src/components/header/header';
import Navbar from '@/src/components/navbar/navbar';
import PaymentMethods from '@/src/components/payment-method/payment-methods';
import Spinner from '@/src/components/spinner/spinner';
import { Subheader } from '@/src/components/subheader/subheader';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { CartModel } from '@/src/models/cart.model';
import { getProductService } from '@/src/services/product.service';
import { TranslationService } from '@/src/services/translation.service';
import { navigate, PageProps } from 'gatsby';
import React, { useState } from 'react';
import { useEffect } from 'react';
import * as styles from './checkout.module.scss';
import Modal from 'react-modal';
import { format } from 'date-fns';
import useAuthGuard from '@/src/hooks/auth-guard.hook';
import { EventService } from '@/src/services/events.service';
import { Helmet } from 'react-helmet';

declare var Stripe: any;
Modal.setAppElement('#___gatsby');

export default function CheckoutPage({ pageContext, location }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [price, setPrice] = useState(0);
  const [cart, setCart] = useState(null as CartModel);
  const [stripe, setStripe] = useState(null);
  const [card, setCard] = useState(null);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusModalHeading, setStatusModalHeading] = useState('');
  const [statusModalBody, setStatusModalBody] = useState('');
  const [statusModalButton, setStatusModalButton] = useState('');
  useAuthGuard(`/${translation.lang}/login`);

  const paymentMethods = [
    {
      slug: 'card',
      name: 'Kort',
      description: 'Kredit- eller debitkort',
      icon: (<div></div>)
    }
  ];

  const durationNames = {
    1: '24h',
    2: '48h',
    3: '72h'
  };

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '90vmin',
      maxWidth: '20rem',
      maxHeight: '20rem'
    }
  };

  useEffect(() => {
    const state = location.state as CartModel;

    if(!state) {
      navigate(`/${translation.lang}/purchase/cart`);
      return;
    }

    setCart(state);

    // Calculate price
    (async () => {
      const productService = getProductService();
      const numAdults = state.travelerCount.travelerCountAdults;
      const numYoungAdults = state.travelerCount.travelerCountYoungAdults;
      const duration = state.passDuration;
      const priceModel = await productService.getPrice('bus-and-ferry');

      console.log(numYoungAdults);

      let price: number;

      if(duration === 3) {
        price = (numYoungAdults * priceModel.childExtended) + (numAdults * priceModel.adultExtended);
      } else {
        price = (numYoungAdults * priceModel.child * duration) + (numAdults * priceModel.adult * duration);
      }

      setPrice(price);
    })();
  }, []);

  useEffect(() => {
    const stripe = Stripe('pk_live_51JCMDDDAasTt7dYcywpKxq9BNqygOm00VMInKDEU2WVVQjV51iKO69VvuelTvlo5gDTfSCtAiTKMOB3GnAv9WnzE00D1ueFc8o');
    const elements = stripe.elements({
      fonts: [
        {
          family: 'sofia-pro',
          cssSrc: 'https://use.typekit.net/zof5nyz.css'
        }
      ]
    });
    setStripe(stripe);

    const options = {
      hidePostalCode: true,
      style: {
        base: {
          fontFamily: 'sofia-pro'
        }
      }
    };

    const card = elements.create('card', options);
    card.mount('#stripe-card-element');
    setCard(card);
  }, []);

  const initiatePayment = async () => {
    if(loading) return;
    setFormError('');
    if(!paymentMethod) {
      setFormError(translation.translate('purchase.checkout.no_payment_method'));
      return;
    };

    setLoading(true);

    const state = location.state as CartModel;

    const productService = getProductService();
    const result = await productService.createPaymentIntent({
      startDate: state.startDate.getTime(),
      duration: state.passDuration,
      product: 'bus-and-ferry',
      travelerCount: {
        travelerCountAdults: state.travelerCount.travelerCountAdults,
        travelerCountChildren: state.travelerCount.travelerCountChildren,
        travelerCountYoungAdults: state.travelerCount.travelerCountYoungAdults,
      }
    });

    if(!result.success) {
      setFormError(result.message);
      setLoading(false);
    } else {
      const clientSecret = result.clientSecret;
      const paymentResult = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: card,
          }
        }
      );
      handlePaymentResult(paymentResult);
      setLoading(false);
    }
  };

  const handlePaymentResult = (result: any) => {
    const { paymentIntent, error } = result;

    if(error) {
      setStatusModalHeading(translation.translate('purchase.checkout.payment_failed_heading'));
      setStatusModalBody(error.message);
      setStatusModalButton(translation.translate('purchase.checkout.try_again_button'));
      setStatusModalOpen(true);
      return;
    }

    if(paymentIntent && paymentIntent.status === 'succeeded') {
      setStatusModalHeading(translation.translate('purchase.checkout.payment_succeeded_heading'));
      setStatusModalBody(translation.translate('purchase.checkout.payment_succeeded_body'));
      setStatusModalButton(translation.translate('purchase.checkout.go_to_passes_button'));
      setStatusModalOpen(true);

      // Track purchase
      const eventService = new EventService();
      eventService.logEvent(
        'purchase',
        {
          numAdults: cart.travelerCount.travelerCountAdults,
          numChildren: cart.travelerCount.travelerCountChildren,
          startDate: cart.startDate,
          duration: cart.passDuration,
          price: price
        }
      );

      // Also track purchase in google analytics
      (window as any).ga(
        'send',
        {
          hitType: 'event',
          eventCategory: 'TodoAlandCheckout',
          eventAction: 'purchase',
          numAdults: cart.travelerCount.travelerCountAdults,
          numChildren: cart.travelerCount.travelerCountChildren,
          startDate: cart.startDate,
          duration: cart.passDuration,
          price: price
        }
      );

      return;
    }

    if(paymentIntent && paymentIntent.status === 'processing') {
      setStatusModalHeading(translation.translate('purchase.checkout.payment_processing_heading'));
      setStatusModalBody(translation.translate('purchase.checkout.payment_processing'));
      setStatusModalButton(translation.translate('purchase.checkout.close_button'));
      setStatusModalOpen(true);
      return;
    }

    if(paymentIntent && paymentIntent.status === 'requires_payment_method') {
      setStatusModalHeading(translation.translate('purchase.checkout.payment_failed_heading'));
      setStatusModalBody(translation.translate('purchase.checkout.payment_failed'));
      setStatusModalButton(translation.translate('purchase.checkout.try_again_button'));
      setStatusModalOpen(true);
      return;
    }
  };

  const handleDialogButton = () => {
    if(statusModalButton === translation.translate('purchase.checkout.go_to_passes_button')) {
      setStatusModalOpen(false);
      navigate(`/${translation.lang}/my-passes`);
    } else {
      setStatusModalOpen(false);
    }
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.purchase_title')}</title>
        <meta name="description" content={translation.translate('helmet.purchase_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      <div className="scrollable">
        <div className={`${styles.content} content`}>
          <Subheader.Stepper firstStep={translation.translate('purchase.cart.choose_pass')} secondStep={translation.translate('purchase.checkout.confirm_and_pay')} activeStep={2}></Subheader.Stepper>

          <div className={styles.container}>
            <div className="app__wrapper">
              <div className={styles.cartInformation}>
                <p className={styles.cartInformation__heading}>{translation.translate('purchase.checkout.my_cart')}</p>
                <div className={styles.cartInformation__price}>
                  {cart ? <strong>Bus & Ferry {durationNames[cart.passDuration]}</strong> : null}
                  <strong>{price}€</strong>
                </div>
                {cart ? <p className={styles.cartInformation__travelers}>{cart.travelerCount.travelerCountAdults} {translation.translate('purchase.checkout.adults')}, {cart.travelerCount.travelerCountYoungAdults} {translation.translate('purchase.checkout.young_adults')}, {cart.travelerCount.travelerCountChildren} {translation.translate('purchase.checkout.children')}</p> : null}
                {cart ? <p className={styles.cartInformation__dates}>{translation.translate('purchase.checkout.start_date') + ': ' + format(cart.startDate, 'dd/MM/yyyy')}</p> : null}
              </div>
            </div>
          </div>

          <div className={styles.paymentMethod__container}>
            <p>{translation.translate('purchase.checkout.payment_method')}</p>
            <PaymentMethods
              value={paymentMethod}
              onChange={method => setPaymentMethod(method)}
              paymentMethods={paymentMethods}></PaymentMethods>
          </div>

          <div className={styles.total__container}>
            <div className={styles.card__container}>
              <div id="stripe-card-element"></div>
            </div>
            <p className={styles.card__disclaimer}>{translation.translate('purchase.checkout.stripe_disclaimer')}</p>

            <div className={styles.totalContainer}>
              <p>{translation.translate('purchase.checkout.total')}</p>
              <strong>{price}€</strong>
            </div>

            <div className={styles.buttonContainer}>
              <LargeButton type="button" disabled={loading} onClick={() => initiatePayment()}>
                {loading ? <Spinner></Spinner> : null}
                <span>{translation.translate('purchase.checkout.confirm_and_pay')}</span>
              </LargeButton>
            </div>
          </div>

          {formError ?
            <div className={styles.errorMessage}>
              {formError}
            </div> : null}
        </div>
        <Footer></Footer>
      </div>
      <Navbar></Navbar>

      <Modal
        isOpen={statusModalOpen}
        onRequestClose={() => setStatusModalOpen(false)}
        contentLabel=""
        style={modalStyles}>
        <h2 className={styles.modalHeading}>{statusModalHeading}</h2>
        <p className={styles.modalBody}>{statusModalBody}</p>
        <button className={styles.modalButton} onClick={() => handleDialogButton()}>{statusModalButton}</button>
      </Modal>
    </TranslationProvider>
  );
}